import { defineStore } from "pinia";

import { useRegionStore } from "@/pages/region/usecases/index";
import { regionRouteListEnum } from "@/pages/region/router/regionRouteList";

import { useProductStore } from "@/pages/product/usecases/index";
import { productRouteListEnum } from "@/pages/product/router/productRouteList";

import { useStockStore } from "@/pages/stock/usecases/index";
import { stockRouteListEnum } from "@/pages/stock/router/stockRouteList";

import { useWorkOrderStore } from "@/pages/workOrder/usecases/index";
import { workOrderRouteListEnum } from "@/pages/workOrder/router/workOrderRouteList";

import { useComboStore } from "@/pages/combo/usecases/index";
import { comboRouteListEnum } from "@/pages/combo/router/comboRouteList";

import { useReportStore } from "@/pages/report/usecases/index";
import { reportRouteListEnum } from "@/pages/report/router/reportRouteList";

import { useBillingRouteStore } from "@/pages/billingRoute/usecases/index";
import { billingRouteListEnum } from "@/pages/billingRoute/router/billingRouteList";

import { useRotaDeEntregaStore } from "@/pages/rotaDeEntrega/usecases/index";
import { rotaDeEntregaRotas } from "@/pages/rotaDeEntrega/router/index";

import { useCustomerStore } from "@/pages/customer/usecases/index";
import { customerRouteListEnum } from "@/pages/customer/router/customerRouteList";

import { usePainelStore } from "@/pages/painel/usecases/index";
import { painelRoutes } from "@/pages/painel/router/index";

export const useMenuItemsStore = defineStore("menuItemsStore", {
  state: () => ({}),
  actions: {
    menuItems() {
      return [
        {
          title: "Geral",
          items: [
            { title: "Início", icon: "mdi-home-outline", to: "/home", permission: true },
            {
              title: "Dashboard",
              icon: "mdi-view-dashboard-outline",
              to: painelRoutes.painelList.path,
              permission: usePainelStore().hasPermissionToOpenModule(),
            },
            {
              title: "Atendimento",
              icon: "mdi-account-outline",
              to: customerRouteListEnum.path,
              permission: useCustomerStore().hasPermissionToOpenModule(),
            },
          ],
        },
        {
          title: "Rotas",
          items: [
            {
              title: "Rotas de cobrança",
              icon: "mdi-map-outline",
              to: billingRouteListEnum.path,
              permission: useBillingRouteStore().hasPermissionToOpenModule(),
            },
            {
              title: "Rotas de entrega",
              icon: "mdi-map-outline",
              to: rotaDeEntregaRotas.listagemDeRotaDeEntregaEnum.path,
              permission: useRotaDeEntregaStore().hasPermissionToOpenModule(),
            },
            {
              title: "Regiões",
              icon: "mdi-map-marker-outline",
              to: regionRouteListEnum.path,
              permission: useRegionStore().hasPermissionToOpenModule(),
            },
          ],
        },
        {
          title: "Relatórios",
          items: [
            {
              title: "Relatórios",
              icon: "mdi-file-chart-outline",
              to: reportRouteListEnum.path,
              permission: useReportStore().hasPermissionToOpenModule(),
            },
          ],
        },
        {
          title: "Gerenciamento",
          items: [
            {
              title: "Combos",
              icon: "mdi-inbox-outline",
              to: comboRouteListEnum.path,
              permission: useComboStore().hasPermissionToOpenModule(),
            },
            {
              title: "Produtos",
              icon: "mdi-package-variant-closed",
              to: productRouteListEnum.path,
              permission:
                useProductStore().hasPermissionToOpenModule() ||
                useStockStore().hasPermissionToOpenModule(),
            },
            {
              title: "Ordem de serviço",
              icon: "mdi-note-outline",
              to: workOrderRouteListEnum.path,
              permission: useWorkOrderStore().hasPermissionToOpenModule(),
            },
          ],
        },
      ];
    },
  },
});
